//@ts-nocheck
import Page from "../Page"
import { useQuery } from "react-query"
import { useState } from "react"
import { QUERY_KEY as ResultQueryKey, listResults } from "../data/result"
import Form from "react-bootstrap/esm/Form"
import Spinner from "react-bootstrap/esm/Spinner"
import Table from "react-bootstrap/esm/Table"
import Pagination from "../components/Pagination"
import { Link, useNavigate } from "react-router-dom"
import Button from "react-bootstrap/esm/Button"
import { formToJson } from "../data/api"

const ResultIndex = () => {
    const [page, setPage] = useState(1)
    const [searchQuery, setSearchQuery] = useState("")
    const navigate = useNavigate()

    const getParams = () => {
        const params = { page }
        if (searchQuery.length > 2) {
            params.search = searchQuery
        }
        return params
    }
    const { data: resultsResponse, ...resultsMeta } = useQuery([ResultQueryKey, getParams()], listResults)
    const handleSearch = (e) => {
        e.preventDefault()
        const data = formToJson(e)
        setSearchQuery(data.search)
    }


    const resultItemCell = resultItem => (
        <>
            <td>{resultItem.position}</td>
            <td>{resultItem.team.college.name}</td>
            <td>{resultItem.grade}</td>
            <td>{resultItem.points}</td>
        </>

    )

    return (
        <Page title="Results">
            <Link to="./add" className="btn btn-primary mb-2">New Result</Link>
            <Form onSubmit={handleSearch}>
                <div className="d-flex mb-3">
                    <div className="me-2 flex-grow-1">
                        <Form.Group>
                        <Form.Control name="search" placeholder="Search for event"/>
                        <Form.Text>Use keywords - event, college, student. Eg: college:Govt College</Form.Text>
                        </Form.Group>
                    </div>
                    <div>
                        <Button type="submit">Search</Button>
                    </div>
                </div>
            </Form>
            {resultsMeta.isLoading ? <Spinner animation="border" /> : (
                <>
                    <Table hover striped bordered>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Category</th>
                                <th>Event</th>
                                <th>Position</th>
                                <th>College</th>
                                <th>Grade</th>
                                <th>Points</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                resultsResponse?.data.results.map((row, idx) => (
                                    <>
                                        <tr>
                                            <td valign="middle" rowSpan={row.items.length}>{idx + 1}</td>
                                            <td valign="middle" rowSpan={row.items.length}>{row.event.category.name}</td>
                                            <td valign="middle" rowSpan={row.items.length}>{row.event.name}</td>
                                            {resultItemCell(row.items[0])}
                                            <td><Button onClick={() => navigate(`./${row.event.id}`)}>Print</Button></td>
                                        </tr>
                                        {
                                            row.items.slice(1).map((resultItem) => (
                                                <tr>
                                                    {resultItemCell(resultItem)}
                                                </tr>))
                                        }
                                    </>
                                ))
                            }
                        </tbody>
                    </Table>
                    <Pagination currentPage={page} itemCount={resultsResponse?.data?.count || 0} setPage={setPage} itemsPerPage={20} />
                </>
            )}
        </Page>)
}

export default ResultIndex
