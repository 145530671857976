//@ts-nocheck
import Page from "../Page"
import { useQuery, useQueryClient } from "react-query"
import { QUERY_KEY as TeamQueryKey, listTeam, deleteTeam, useHasConfirmed } from "../data/team"
import Spinner from "react-bootstrap/esm/Spinner"
import Card from "react-bootstrap/esm/Card"
import Table from "react-bootstrap/esm/Table"
import Button from "react-bootstrap/esm/Button"
import Modal from "react-bootstrap/esm/Modal"
import Alert from "react-bootstrap/esm/Alert"
import { Link } from "react-router-dom"
import Row from "react-bootstrap/esm/Row"
import keyBy from "lodash/keyBy"
import { useState } from "react"
import { QUERY_KEY as StudentQueryKey } from "../data/student"
import HasConfirmedBlocker from "./HasConfirmedBlocker"
import Pagination from "../components/Pagination"
import debounce from "lodash/debounce"
import Form from "react-bootstrap/Form"

const AddTeamButton = ({ disabled }) => (
    <Link to="new"><Button type="button" disabled={disabled}>Add Team</Button></Link>
)

const TeamIndex: React.FC = () => {
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [teamToDelete, setTeamToDelete] = useState(undefined)
    const [showDeleteStatus, setShowDeleteStatus] = useState({ show: false, deletedEventName: undefined, error: undefined })
    const [pageNumber, setPageNumber] = useState(1)
    const [searchQuery, setSearchQuery] = useState(undefined)

    const queryClient = useQueryClient()
    const confirmationStatus = useHasConfirmed()

    const getParams = () => {
        const params = {
            page: pageNumber,
            page_size: 20
        }
        if (searchQuery) {
            params.search = searchQuery
        }
        return params
    }

    const { data: teams, ...teamsMeta } = useQuery([TeamQueryKey, getParams()], listTeam, {
        staleTime: Infinity
    })
    const teamsMap = keyBy(teams?.data.results || [], "id")

    const debouncedSearch = debounce(
        (event) => {
            const query = event.target.value
            if (query.length >= 3) setSearchQuery(query)
            else setSearchQuery(undefined)
            setPageNumber(1)
        }, 500)

    const handleClickOnDelete = (teamId) => () => {
        setTeamToDelete(teamId)
        setShowDeleteModal(true)
    }

    const handleConfirmDelete = () => {
        const deletedEventName = teamsMap[teamToDelete]?.event?.name
        if (confirmationStatus.hasConfirmed) { return }
        else {
            deleteTeam(teamToDelete)
                .then(response => {
                    if (response.status === 204) {
                        setShowDeleteStatus({ show: true, deletedEventName: deletedEventName, error: undefined })
                        queryClient.invalidateQueries(TeamQueryKey)
                        queryClient.invalidateQueries(StudentQueryKey)
                    } else {
                        setShowDeleteStatus({ show: false, deletedEventName: undefined, error: response?.data })
                    }
                })
                .catch(() => {
                    setShowDeleteStatus({ show: false, deletedEventName: undefined, error: "Some error occurred." })
                })
                .finally(() => {
                    setShowDeleteModal(false)
                })
        }
    }

    const handleDeleteCanceled = () => {
        setTeamToDelete(undefined)
        setShowDeleteModal(false)
    }

    return (
        <Page title="Teams">
            <Modal show={showDeleteModal} onHide={handleDeleteCanceled}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Confirm you want to delete the team for <strong>{teamsMap[teamToDelete]?.event?.name}</strong>?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDeleteCanceled}>
                        No, Cancel.
                    </Button>
                    <Button variant="danger" onClick={handleConfirmDelete}>
                        Yes, Delete.
                    </Button>
                </Modal.Footer>
            </Modal>
            <HasConfirmedBlocker confirmationStatus={confirmationStatus} />
            {
                showDeleteStatus.show && showDeleteStatus.deletedEventName
                    ? (
                        <Alert variant="success" onClose={() => setShowDeleteStatus({ show: false })} >
                            Deleted the team for <strong>{showDeleteStatus.deletedEventName}</strong>
                        </Alert>
                    ) : null
            }
            {
                showDeleteStatus.show && !!showDeleteStatus.error
                    ? (
                        <Alert variant="danger" onClose={() => setShowDeleteStatus({ show: false })} >
                            Some error occurred.<strong>{JSON.stringify(showDeleteStatus.error)}</strong>
                        </Alert>
                    ) : null
            }
            {
                teamsMeta.isLoading ? <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner> :
                    teams?.data.count === 0 ?
                        <div className="w-75">
                            <Card>
                                <Card.Body>
                                    <Card.Title>No Teams were added.</Card.Title>
                                    Once students are added, add teams.
                                    After adding all teams, proceed to confirmation and attestation.
                                </Card.Body>
                                <Card.Footer>
                                    <AddTeamButton disabled={confirmationStatus.hasConfirmed} />
                                </Card.Footer>
                            </Card>
                        </div>
                        :
                        <>

                            <Row className="mb-2">
                                <div className="d-flex align-items-center">
                                    <div className="me-2 flex-grow-1">
                                        <Form.Control
                                            type="text"
                                            placeholder="Search student by name or registration ID"
                                            onChange={debouncedSearch}
                                        />
                                    </div>

                                    <AddTeamButton disabled={confirmationStatus.hasConfirmed} />
                                </div>
                            </Row>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Category</th>
                                        <th>Event</th>
                                        <th>Members</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {teams?.data.results.map((team) => (
                                        <tr>
                                            <td>
                                                {team.event.category.name}
                                            </td>
                                            <td>
                                                {team.event.name}
                                            </td>
                                            <td>
                                                <ol>
                                                    {team.students.map(student =>
                                                        <li><strong>{student.name}</strong>, {student.class_name}</li>
                                                    )}
                                                </ol>
                                            </td>
                                            <td>
                                                <Link to={`new?team_id=${team.id}`} className="me-2">
                                                    <Button disabled={confirmationStatus.hasConfirmed} variant="primary" size="sm">Edit</Button>
                                                </Link>
                                                <Button disabled={confirmationStatus.hasConfirmed} variant="danger" size="sm" onClick={handleClickOnDelete(team.id)}>Delete</Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <Pagination currentPage={pageNumber} itemCount={teams?.data?.count || 0} setPage={setPageNumber} itemsPerPage={20} />
                        </>
            }
        </Page>
    )
}

export default TeamIndex
