//@ts-nocheck
import jwt_decode from "jwt-decode";
import { authAxios, AUTH_TOKEN_KEY, noAuthAxios } from "./api";

export const login = (authToken: string) => {
  localStorage.setItem(AUTH_TOKEN_KEY, authToken);
};

export const logout = () => {
  localStorage.removeItem(AUTH_TOKEN_KEY);
  window.location.href = "/login";
};

export const useIsLoggedIn = () => {
  const authToken = localStorage.getItem(AUTH_TOKEN_KEY) || "";
  try {
    jwt_decode(authToken);
    return true;
  } catch (error) {
    return false;
  }
};

export const getRole = () => {
  const authToken = localStorage.getItem(AUTH_TOKEN_KEY) || "";
  try {
    const decoded = jwt_decode(authToken);
    return decoded.type.toLowerCase();
  } catch (error) {
    return false;
  }
};

export const useIsAdmin = () => {
  const role = getRole();
  return role === "admin";
};

export const useIsCollege = () => {
  const role = getRole();
  return role === "college";
};

export const useIsResultUser = () => {
  const role = getRole();
  return role === "result";
};

export const postLogin = ({ username, password }) => {
  return noAuthAxios.post("/auth/login/", { username, password });
};

export const changePassword = ({ old_password, new_password }) => {
  return authAxios.post("/app/users/change_password/", {
    old_password,
    new_password,
  });
};
