import { FestDetails } from "../utils/static";

export const LastStep = () => (
  <div>
    As the last step of registration,
    <ul>
      <li>
        Take the printout of the college submission and verify that every
        details given are correct and valid..
      </li>
      <li>All pages should be attested by the Principal of the college.</li>
      {FestDetails.email && (
        <li>
          Scan the printed documented as a PDF and send as email to{" "}
          {FestDetails.email}
        </li>
      )}
    </ul>
  </div>
);
