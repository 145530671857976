//@ts-nocheck
import React from "react"
import Page from "../Page"
import { useQuery } from "react-query"
import { teamStats } from "../data/team"
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"
import Spinner from "react-bootstrap/esm/Spinner"
import Table from "react-bootstrap/esm/Table"
import Card from "react-bootstrap/esm/Card"
import Alert from "react-bootstrap/esm/Alert"
import { LastStep } from "../team/LastStep"

const Dashboard: React.FC = props => {
    const { data: stats, ...statsMeta } = useQuery("stats", teamStats, { staleTime: Infinity })
    return (
        <Page title="Home">
            <div className="my-2 display-6">
                How to Register
            </div>
            <Row>
                <Col xs={12} md={6}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Step 1: Add Students</Card.Title>
                            <Card.Text>
                                <p>First step is to add all the students.  <br />
                                            Please keep the following details while adding the students.
                                            <ol>
                                        <li>Name, Gender, Date of Birth, Class(or Batch), College Admission Number</li>
                                        <li>Phone number should be of the student. Phone number may be used during the fest for app downlaods.</li>
                                        <li>Photo: Minimum 400h x 300w in pixels. Maximum 128kb size. Accepts .jpg, .png</li>
                                        <li>College ID Card, or letter with photo attested by Principal. Maximum 128kb size. Accepts .jpg, .png, .pdf</li>
                                    </ol>
                                </p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={6}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Step 2: Add Teams</Card.Title>
                            <Card.Text>
                                <p>
                                    Second step is to add all individual and group teams.  <br />
                                    Each College can register one team per category. <br />
                                    Each team can have 7 members and 3 supporting members. <br />
                                    {/* Following rules needs to be followed while registering.
                                    <ul>
                                        <li>One Student can participate in a maximum of 4 Individual On-Stage items.</li>
                                        <li>One Student can participate in a maximum of 4 Individual Off-Stage items.</li>
                                        <li>There is no restriction for a student participating in multiple group items.</li>
                                    </ul>
                                            Maximum number of teams allowed per college in different Categories
                                            <Table striped bordered>
                                        <thead>
                                            <tr>
                                                <th>Category</th>
                                                <th>Total</th>
                                                <th>Maximum</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr> <td>Chithrolsavam (Off-Stage)</td>  <td>16</td> <td>9 </td></tr>
                                            <tr> <td>Sahithyolsavam (Off-Stage)</td>  <td>59</td> <td>27 </td></tr>
                                            <tr> <td>Nritholsavam (On-Stage)</td> <td>22</td>  <td>12 </td></tr>
                                            <tr> <td>Sangeetholsavam (On-Stage)</td>  <td>30</td> <td>17 </td></tr>
                                            <tr> <td>Drishya-Natakolsavam (On-Stage)</td> <td>14</td>  <td>8 </td></tr>
                                            <tr> <td>Natakam (4 languages)</td>  <td>4</td> <td>2 </td></tr>
                                        </tbody>
                                    </Table> */}
                                </p>
                            </Card.Text>
                        </Card.Body>
                    </Card>

                </Col>
                </Row>
                <Row className="mt-2">
                <Col xs={12} md={6}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Step 3: Confirm Submission</Card.Title>
                            <Card.Text>
                                <Alert variant="danger">IMPORTANT</Alert>
                                <p>This is a very important step.</p>
                                <p>Submissions should be confirmed only after all students and teams has been entered and verified.</p>
                                <p><strong>NO CHANGES WILL BE ALLOWED AFTER CONFIRMATION</strong></p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col xs={12} md={6}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Step 4: Print, Attest and Email</Card.Title>
                            <Card.Text>
                                <LastStep />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6}>
                    <div className="display-6 my-2">Stats</div>
            <Card>
                <Card.Body>
                    <Card.Text>

                        {
                            statsMeta.isLoading ? <Spinner animation="border" /> : (
                                <Table striped hover>
                                    <tbody>
                                    <tr>
                                        <td>Number of Students</td>
                                        <td>{stats?.data?.students_count}</td>
                                    </tr>
                                    <tr>
                                        <td>Number of Teams</td>
                                        <td>{stats?.data?.teams_count}</td>
                                    </tr>
                                    {
                                        (stats?.data?.category_wise_count || []).map(row => (
                                            <tr>
                                                <td>Number of teams in {row.category_name}</td>
                                                <td>{row.count}</td>
                                            </tr>
                                        ))
                                    }
</tbody>
                                </Table>
                            )
                        }
                    </Card.Text>
                </Card.Body>
            </Card>
            </Col>
            </Row>
            
        </Page>
    )
}

export default Dashboard
