import { Container, Nav, Navbar } from "react-bootstrap";
import { dateStrForFestival } from "../utils/date";
import { FestDetails } from "../utils/static";

const Website = () => {
  return (
    <div
      className="vw-100 website"
      style={{ backgroundColor: "rgb(253 239 211)" }}
    >
      <div className="d-flex flex-wrap align-items-center mx-auto justify-content-center">
        <img src={FestDetails.logo} alt="logo" height={150} />
        <div>
          <h2 className="text-center">{FestDetails.name}</h2>
          <h4 className="text-center">{dateStrForFestival(FestDetails)}</h4>
          <h4 className="text-center">{FestDetails.venue}</h4>
        </div>
      </div>

      <Navbar collapseOnSelect expand="md">
        <Navbar.Brand href="#home"></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-center"
        >
          <Nav className="d-flex justify-content-center align-items-center">
            {/* <Nav.Link href="#rules">Rules &amp; Manual</Nav.Link> */}
            <Nav.Link href="/login">Register</Nav.Link>
            <Nav.Link href="#venue">Venue College</Nav.Link>
            <Nav.Link href="#contact">Contact Us</Nav.Link>
            <Nav.Link href="#how-to-reach">How to Reach</Nav.Link>
            {/* <Nav.Link href="#bright-future">Bright Future</Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <Container>
        <div className="d-flex flex-wrap flex-md-nowrap align-items-center mx-auto justify-content-center">
          <div>
            <h2>Kalotsavam 2024</h2>
            <p>
            Welcome to the Kannur University Arts Festival 2024 - a vibrant
            celebration of creativity, talent, and cultural diversity! Hosted by
            Kannur University, this annual extravaganza promises to be a
            kaleidoscope of artistic expressions, bringing together students
            from various disciplines to showcase their prowess in the realms of
            music, dance, drama, and visual arts.
            </p>
            <div>
              <a href="/login" className="px-2">Register Now</a>
              <a href="https://kalotsavam.sgp1.cdn.digitaloceanspaces.com/assets/kannur-2024/kalotsavam-manual-corrected.pdf" className="px-2">Download Manual</a>
            </div>
          </div>
          <img src={FestDetails.logo} alt="logo" height={400} />
        </div>
      </Container>

      <Container className="mt-4" id="venue">
        <h2>Venue</h2>
        <h4>{FestDetails.venue}</h4>
        <div className="d-flex flex-wrap">
          <img src="/mannad-college-01.jpeg" alt="College 01" width={300} />
          <img src="/mannad-college-02.jpeg" alt="College 02" width={300} />
          <img src="/mannad-college-03.jpeg" alt="College 03" width={300} />
        </div>
      </Container>
      <Container className="mt-4" id="contact">
        <h2>Contact Us</h2>
        <div className="d-flex flex-wrap justify-content-between">
          {FestDetails.website.contact_numbers?.map((c) => (
            <div className="">
              <b>{c.name}</b><br />
              {c.phone_number}

            </div>
          ))}
        </div>
      </Container>
      <Container  className="mt-4" id="how-to-reach">
        <h2>How to Reach</h2>
        <div className="d-flex">
          <img src="/map.jpg" alt="College 01" width={300} />
          
        </div>
      </Container>
    </div>
  );
};

export default Website;
