//@ts-nocheck
import Form from "react-bootstrap/esm/Form"
import EventSelect from "../components/EventSelect"
import TeamSelect from "../components/TeamSelect"
import { useState, useEffect } from "react"
import Page from "../Page"
import Table from "react-bootstrap/esm/Table"
import Button from "react-bootstrap/esm/Button"
import times from "lodash/times"
import { formToJson } from "../data/api"
import { setApiStatus } from "../utils/apiStatus"
import { addResult, QUERY_KEY as ResultQueryKey } from "../data/result"
import ResponseAlert from "../components/ResponseAlert"
import { useQueryClient } from "react-query"

const RESULT_ROW_LENGTH = 15

const NewResult = () => {
    const [event, setEvent] = useState(null)
    const [teamSize, setTeamSize] = useState(1)
    const [apiResponse, setApiResponse] = useState({loading: false, showAlert: false})

    const [selectedTeams, setSelectedTeams] = useState({}) // {idx: teamId }
    const queryClient = useQueryClient()

    const handleSelectTeam = idx => (e) => setSelectedTeams(teams => {
        console.log(`DEBUG [idx, e]': ${JSON.stringify([idx, e])}`);
        return ({...teams, [idx]: e[0]?.id})
    })

    const handleAddResult = (e) => {
        e.preventDefault()

        const data = formToJson(e)
        const formDataToSubmit = {
            event, 
            items: []
        }
        console.log(`DEBUG data': ${JSON.stringify(data)}`);
        console.log(`DEBUG selectedTeams': ${JSON.stringify(selectedTeams)}`);

        times(RESULT_ROW_LENGTH, idx => {
            if (data[`position${idx}`] && data[`grade${idx}`] && selectedTeams[idx]) {
                formDataToSubmit.items.push(
                    { team: selectedTeams[idx], position: data[`position${idx}`], grade: data[`grade${idx}`] }
                )
            }
        })

        setApiStatus(addResult, formDataToSubmit, setApiResponse)
    }

    useEffect(() => {
        queryClient.invalidateQueries(ResultQueryKey)
    }, [apiResponse])

    useEffect(() => {
        console.log(`DEBUG teams': ${JSON.stringify(selectedTeams)}`);
    }, [selectedTeams])


    return (
        <Page title="Update Results">
            <Form name="result-form" onSubmit={handleAddResult}>
                <Form.Group key="event">
                    <EventSelect onChange={e => {
                        setTeamSize(e.length ? e[0].max_participants : null)
                        setEvent(e.length ? e[0].id : null)
                    }} />
                </Form.Group>
                {
                    event && (
                        <Table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Position</th>
                                    <th>Grade</th>
                                    <th>Team</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    times(RESULT_ROW_LENGTH, idx => (
                                        <tr>
                                            <td>{idx + 1}</td>
                                            <td>
                                                <Form.Control type="number" name={`position${idx}`} min={1} max={3} />
                                            </td>
                                            <td>
                                                <Form.Select name={`grade${idx}`}>
                                                    <option value="A">A</option>
                                                    <option value="B">B</option>
                                                    <option value="C">C</option>
                                                    <option value="N">No</option>
                                                </Form.Select>
                                            </td>
                                            <td>
                                                <TeamSelect
                                                    event={event} teamSize={teamSize}
                                                    name={`team${idx}`} showLabel={false}
                                                    onChange={handleSelectTeam(idx)}
                                                />
                                            </td>
                                        </tr>))}

                            </tbody>
                        </Table>)
                }
                <div className="d-grid">
                    <Button type="submit">Submit</Button>
                </div>
                <ResponseAlert
                        status={apiResponse}
                        successHeader=""
                        successBody="Added"
                    />
            </Form>
        </Page>
    )
}

export default NewResult
